import Link from "next/link";
import { useTranslation } from "next-i18next";

export default function SubmenuServices() {
  const { t } = useTranslation("header");
  const services = [
    {
      title: t("services.security_assessment", { ns: "common" }),
      items: [
        {
          avatar: "/images/products/pentest.svg",
          label: t("services.penetration_testing", { ns: "common" }),
          link: "/services/penetration-testing",
        },
        {
          avatar: "/images/products/red-team.svg",
          label: "Red Teaming",
          link: "/red-teaming",
        },
        {
          avatar: "/images/products/internet-audit.svg",
          label: t("services.infrastructure_security_audit", { ns: "common" }),
          link: "/services/infrastructure",
        },
        {
          avatar: "/images/products/performance-test.svg",
          label: t("services.performance_testing", { ns: "common" }),
          link: "/services/performance-testing ",
        },
      ],
    },
    {
      title: t("submenu_services.security_opetations", { ns: "header" }),
      items: [
        {
          avatar: "/images/products/vul-management.svg",
          label: t("services.vulnerability_management", { ns: "common" }),
          link: "/services/vulnerability-management",
        },
        {
          avatar: "/images/products/security-monitoring.svg",
          label: t("services.security_monitoring", { ns: "common" }),
          link: "/services/security-monitoring",
        },
        {
          avatar: "/images/products/managed-bug-bounty.svg",
          label: t("services.managed_bug_bounty", { ns: "common" }),
          link: "/services/managed-bug-bounty",
        },
      ],
    },
    {
      title: t("services.security_consulting", { ns: "common" }),
      items: [
        {
          avatar: "/images/products/security-policy-development.svg",
          label: t("services.security_policy_development", { ns: "common" }),
          link: "/services/security-policy-development",
        },
        {
          avatar: "/images/products/incident-response.svg",
          label: t("services.incident_response", { ns: "common" }),
          link: "/services/incident-response",
        },
        {
          avatar: "/images/products/security-training.svg",
          label: t("services.security_training", { ns: "common" }),
          link: "/services/security-training",
        },
      ],
    },
    {
      title: t("products.blockchain_security"),
      items: [
        {
          avatar: "/images/products/blockchain-audit.svg",
          label: t("services.blockchain_protocol_audit", { ns: "common" }),
          link: "/services/blockchain-protocol-audit",
        },
        {
          avatar: "/images/products/smart-contract-audit.svg",
          label: t("products.smart_contract_audit", { ns: "common" }),
          link: "/services/smart-contract-audit",
        },
      ],
    },
  ];
  return (
    <div className="grid grid-cols-4 gap-4 p-8 bg-white lg:grid-cols-2 sm:grid-cols-1 lg:p-4 md:max-h-[calc(100dvh-210px)] overflow-y-auto">
      {services.map((p, index) => (
        <div key={index} className="col-span-1">
          <h4 className="text-grey text-[16px] mb-6 tracking-wide font-medium px-3">
            {p.title}
          </h4>
          <ul className="p-0">
            {p.items.map((item, i) => {
              return (
                <Link key={i} href={item.link || "/"}>
                  <li className="flex items-center p-3 mb-2 cursor-pointer gap-x-6 group text-body4 last:mb-0">
                    <img
                      src={item.avatar}
                      className="w-[22px] h-[22px] object-contain"
                      alt="CyStack image avatar product"
                    />
                    <span className="font-semibold text-[16px] leading-6 group-hover:underline text-dark-title">
                      {item.label}
                    </span>
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
}
