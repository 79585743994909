// import useTranslation from '@/hooks/useTranslation'

import AcceptCookies from "@/components/AcceptCookies";
import Facebook from "@/public/icons/Facebook.svg";
import Github from "@/public/icons/Github.svg";
import Link from "next/link";
import Linkein from "@/public/icons/Linkein.svg";
import Twitter from "@/public/icons/Twitter.svg";
import Youtube from "@/public/icons/Youtube.svg";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
export default function CystackFooter({ subdomain }) {
  const { t } = useTranslation("footer");

  const customStyles = `
    @media only screen and (min-width: 1200px) and (max-width: 1280px) {
      .custom-col {
        flex: 0 0 25%; /* Equivalent to span=6 in a 12 column grid */
        max-width: 25%; /* Equivalent to span=6 in a 12 column grid */
      }
    }
  `;

  const [cystackSocial] = useState([
    {
      link: "https://www.facebook.com/cystackvn",
      logo: <Facebook className="" />,
    },
    {
      link: "https://twitter.com/cystacksecurity",
      logo: <Twitter className="" />,
    },
    {
      link: "https://www.linkedin.com/company/cystackcorp/mycompany/",
      logo: <Linkein className="" />,
    },
    {
      link: "https://www.youtube.com/@cystacksecurity",
      logo: <Youtube className="" />,
    },
    {
      link: "https://github.com/cystack",
      logo: <Github className="" />,
    },
  ]);

  const Audit = {
    title: "Audit",
    items: [
      {
        label: "CyStack VulnScan",
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/vulnscan`
          : "/vulnscan",
        external: !!subdomain,
      },
      {
        label: t("services.penetration_testing", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/penetration-testing`
          : "/services/penetration-testing",
        external: !!subdomain,
      },
      {
        label: "Red teaming",
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/red-teaming`
          : "/red-teaming",
        external: !!subdomain,
      },
      {
        label: t("title", { ns: "infrastructure" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/infrastructure`
          : "/services/infrastructure",
        external: !!subdomain,
      },
      {
        label: t("services.performance_testing", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/performance-testing`
          : "/services/performance-testing",
        external: !!subdomain,
      },
    ],
  };
  const SecurityOperations = {
    title: "Security Operations",
    items: [
      {
        label: t("features.item_1.title", { ns: "security-operations" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/endpoint`
          : "/endpoint",
        external: !!subdomain,
      },
      {
        label: t("services.vulnerability_management", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/vulnerability-management`
          : "/services/vulnerability-management",
        external: !!subdomain,
      },
      {
        label: t("services.security_monitoring", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/security-monitoring`
          : "/services/security-monitoring",
        external: !!subdomain,
      },
      {
        label: t("services.incident_response", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/incident-response`
          : "/services/incident-response",
        external: !!subdomain,
      },
      {
        label: t("services.managed_bug_bounty", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/managed-bug-bounty`
          : "/services/managed-bug-bounty",
        external: !!subdomain,
      },
    ],
  };

  const DataSecurity = {
    title: "Data Security",
    items: [
      {
        label: "Password Manager",
        link: "https://locker.io/passwords",
        external: true,
      },
      {
        label: "Secret Manager",
        link: "https://locker.io/secrets",
        external: true,
      },
      {
        label: t("services.data_leak_detection", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/data-leak-detection`
          : "/data-leak-detection",
        external: !!subdomain,
      },
    ],
  };

  const SecurityCompliance = {
    title: "Security Compliance",
    items: [
      {
        label: "Security Policy Development",
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/security-policy-development`
          : "/security-policy-development",
        external: !!subdomain,
      },
      {
        label: t("services.security_training", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/security-training`
          : "/services/security-training",
        external: !!subdomain,
      },
      {
        label: "Trust Center",
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/trust-center`
          : "/trust-center",
        external: !!subdomain,
      },
    ],
  };
  const BlockchainSecurity = {
    title: "Blockchain Security",
    items: [
      {
        label: t("products.smart_contract_audit", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/smart-contract-audit`
          : "/services/smart-contract-audit",
        external: !!subdomain,
      },
      {
        label: t("services.blockchain_protocol_audit", { ns: "common" }),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/services/blockchain-protocol-audit`
          : "/services/blockchain-protocol-audit",
        external: !!subdomain,
      },
      {
        label: "Smart Contract VulnScan",
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/vulnscan`
          : "/vulnscan",
        external: !!subdomain,
      },
    ],
  };

  const Company = {
    title: "Company",
    items: [
      {
        label: t("company.about_us"),
        link: subdomain ? `${process.env.NEXT_PUBLIC_DOMAIN}/about` : "/about",
        external: !!subdomain,
      },
      {
        label: t("company.brand"),
        link: subdomain ? `${process.env.NEXT_PUBLIC_DOMAIN}/brand` : "/brand",
        external: !!subdomain,
      },
      {
        label: t("company.press"),
        link: subdomain ? `${process.env.NEXT_PUBLIC_DOMAIN}/press` : "/press",
        external: !!subdomain,
      },
      {
        label: t("company.contact"),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/contact`
          : "/contact",
        external: !!subdomain,
      },
      {
        label: t("company.careers"),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/careers`
          : "/careers",
        external: !!subdomain,
      },
    ],
  };
  const Resources = {
    title: "Resources",
    items: [
      {
        label: t("resources.blog"),
        link: subdomain ? `${process.env.NEXT_PUBLIC_DOMAIN}/blog` : "/blog",
        external: !!subdomain,
      },
      {
        label: t("resources.research"),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/research`
          : "/research",
        external: !!subdomain,
      },
      {
        label: t("resources.projects"),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/projects`
          : "/projects",
        external: !!subdomain,
      },
      {
        label: t("resources.case_studies"),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/customers`
          : "/customers",
        external: !!subdomain,
      },
      {
        label: t("resources.help_center"),
        link: subdomain
          ? `${process.env.NEXT_PUBLIC_DOMAIN}/careers/open-roles`
          : "/careers/open-roles",
        external: !!subdomain,
      },
    ],
  };

  return (
    <>
      <style>{customStyles}</style>
      <footer className="relative text-white bg-black cystack-footer">
        <div className="fixed bottom-0 left-0 right-0 z-[2]">
          <AcceptCookies />
        </div>
        <section>
          <div className="new-container">
            <div className="flex justify-between py-20 lg:flex-col lg:justify-start lg:items-start lg:gap-10">
              <div className="max-w-[400px] flex-shrink-0">
                <img
                  src="/images/logo-white.svg"
                  className="w-[290px] object-contain mb-5"
                  alt="CyStack information"
                />
                <div className="flex items-center gap-5 mb-12 social">
                  {cystackSocial.map((social, index) => (
                    <a href={social.link} target="_blank" key={index}>
                      {social.logo}
                    </a>
                  ))}
                </div>
                <div className="text-[#BFBFBF] max-w-[400px] text-[16px]">
                  <p className="leading-[24px] mb-2">
                    {t("heading.address")}:{" "}
                    {t("company_info.address", { ns: "common" })}
                  </p>
                  <p className="leading-[24px] mb-2">
                    {t("heading.email")}: contact@cystack.net
                  </p>
                  <p className="leading-[24px]">
                    {t("heading.phone")}: (+84) 247 109 9656
                  </p>
                </div>
                <div className="flex gap-5 mt-12">
                  <div className="flex flex-col items-center gap-2">
                    <Image
                      src={"/images/layout/Iso2015.svg"}
                      width={82}
                      height={80}
                      alt="Locker ISO 9001:2015"
                    />
                    <span className="text-black uppercase text-sm font-bold bg-white bg-opacity-70 text-center py-[4px] inline-block w-full">
                      certified
                    </span>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <Image
                      src={"/images/layout/Iso2022.svg"}
                      width={86}
                      height={80}
                      alt="Locker ISO 9001:2015"
                    />
                    <span className="text-black uppercase text-sm font-bold bg-white bg-opacity-70 text-center py-[4px] inline-block w-full">
                      certified
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-end flex-1 gap-10 xl:gap-5 md:justify-start md:gap-0">
                <div className="flex flex-col gap-6 one-col min-w-[260px] md:min-w-[50%] sm:w-full">
                  <div className="flex flex-col gap-4">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {Audit.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {Audit.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {SecurityOperations.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {SecurityOperations.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col gap-6 one-col min-w-[260px] md:min-w-[50%] sm:w-full">
                  <div className="flex flex-col gap-4">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {DataSecurity.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {DataSecurity.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {SecurityCompliance.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {SecurityCompliance.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {BlockchainSecurity.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {BlockchainSecurity.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col gap-6 one-col w-fit md:flex-row md:w-full md:gap-0 md:mt-5">
                  <div className="flex flex-col gap-4 md:min-w-[50%]">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {Company.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {Company.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4 md:min-w-[50%]">
                    <h3 className="text-[15px] leading-6 font-bold font-cystack text-white uppercase tracking-[2px]">
                      {Resources.title}
                    </h3>
                    <ul className="flex flex-col gap-2 mb-0 list-none">
                      {Resources.items.map((item, i) => (
                        <li className="flex items-center" key={item.label}>
                          {item.external ? (
                            <a href={item.link || "/"} target="_blank">
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </a>
                          ) : (
                            <Link href={item.link || "/"}>
                              <span className="text-[#BFBFBF] cursor-pointer text-[16px] leading-6 hover:underline">
                                {item.label}
                              </span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-solid border-l-0 border-r-0 border-b-0 border-[#303030]">
            <div className="flex items-center justify-between py-6 new-container lg:flex-col lg:justify-start sm:items-start">
              <p className="text-body5sb lg:mb-8 text-[#8C8C8C]">
                © {dayjs().year()} by CyStack.,JSC. All rights reserved.
              </p>
              <ul className="flex items-center p-0 m-0 list-none sm:flex-col sm:items-start">
                <li className="cursor-pointer text-body5sb mr-7 sm:mb-3 sm:mr-0 last:mr-0 text-[#8C8C8C]">
                  <Link
                    href={
                      subdomain
                        ? `${process.env.NEXT_PUBLIC_DOMAIN}/terms-of-service`
                        : "/terms-of-service"
                    }
                    passHref
                  >
                    <a target={subdomain ? "_blank" : ""}>
                      <span>{t("bottom.label_1")}</span>
                    </a>
                  </Link>
                </li>
                <li className="cursor-pointer text-body5sb mr-7 sm:mb-3 sm:mr-0 last:mr-0 text-[#8C8C8C]">
                  <Link
                    href={
                      subdomain
                        ? `${process.env.NEXT_PUBLIC_DOMAIN}/security`
                        : "/security"
                    }
                    passHref
                  >
                    <a target={subdomain ? "_blank" : ""}>
                      <span>{t("bottom.label_2")}</span>
                    </a>
                  </Link>
                </li>
                <li className="cursor-pointer text-body5sb mr-7 sm:mb-3 sm:mr-0 last:mr-0 text-[#8C8C8C]">
                  <Link
                    href={
                      subdomain
                        ? `${process.env.NEXT_PUBLIC_DOMAIN}/privacy`
                        : "/privacy"
                    }
                    passHref
                  >
                    <a target={subdomain ? "_blank" : ""}>
                      <span>{t("bottom.label_3")}</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
